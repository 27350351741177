.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  font-size: 0.8rem;
  width: 100vw;
  position: fixed;
  top: 0;
  background-color: white;
  height: 3.5rem;
  z-index: 1;
}
.nav-menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
li {
  list-style: none;
}
.nav-item {
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  padding: 0rem 1rem;
}

.home {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0rem;
}
.container {
  text-align: left;
}
.headline {
  font-size: 2rem;
  padding: 2rem;
  color: black;
}
.description {
  max-width: 40rem;
  font-size: 1rem;
  padding: 2rem;
  text-align: center;
  color: black;
}
.button-container {
  display: flex;
  padding: 2rem;
  flex-direction: row;
  justify-content: center;
}
.button {
  width: 8rem;
  font-size: 0.75rem;
  padding: 1rem 1rem;
  background: black;
  color: white;
  font-weight: bold;
  border: 0.1rem solid white;
  border-radius: 2rem;
  text-decoration: none;
  text-align: center;
  margin-right: 3rem;
  margin-bottom: 3rem;
}

@media screen and (max-width: 960px) {
  .headline {
    font-size: 2rem;
  }
  .description {
    font-size: 1rem;
    /* max-width: 20rem; */
  }
  .button-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .button {
    width: 6rem;
    font-size: 0.75rem;
    padding: 0.75rem 0.75rem;
    background: white;
    color: black;
    font-weight: bold;
    border: 0.1rem solid white;
    border-radius: 2rem;
    text-decoration: none;
    text-align: center;
    margin-right: 0rem;
    margin-bottom: 3rem;
  }
}

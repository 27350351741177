.contact {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title {
  padding: 2rem;
  color: black;
}
.icon {
  padding: 2rem;
}
.iconStyle {
  fill: black;
}
